.stories-wrapper {
  padding-top: 8vh;
  position: relative;
}
/* .Story-card div:first-child {
	background: transparent !important;
} */
.stories-wrapper .Story-card {
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}
.stories-wrapper p.previous {
  position: absolute;
  left: -20%;
  top: 42%;
  font-size: 47px;
}
.stories-wrapper p button {
  background: transparent;
  border: 0px;
  color: #fff;
}
.stories-wrapper p.next {
  position: absolute;
  right: -20%;
  top: 42%;
  font-size: 47px;
  z-index: 2;
}
.stories-wrapper .bottom-bar {
  position: absolute;
  bottom: 0;
  margin: 0px auto;
  width: 63%;
  z-index: 999;
  background: #000;
  padding: 10px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.stories-wrapper .bottom-bar .comment .form-control {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  border-bottom: 1px solid #fff;
  font-size: 13px;
  color: #fff;
  padding-left: 0;
}
.stories-wrapper .bottom-bar .comment {
  position: relative;
  display: flex;
  width: 80%;
}
.stories-wrapper .bottom-bar .comment button {
  border: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  color: #fff;
  position: absolute;
  right: 0;
}
.stories-wrapper .bottom-bar button {
  border: 0;
  background: transparent;
}
.stories-wrapper .bottom-bar .gift button {
  background: #0f0f0f;
  border-radius: 50%;
  width: 52px;
  height: 50px;
}
.stories-wrapper .bottom-bar .gift button img {
  width: 30px;
  height: 40px;
}
.stories-wrapper .close-icon a {
  background: transparent;
  border: 0;
  color: #fff;
  fill: #fff;
  position: absolute;
  right: 5%;
  top: 10%;
}
