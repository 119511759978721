/* Invite Css Start Here */
.copy-link {
	padding: 120px 0;
}
.copy-link {
	padding: 120px 0;
}
.copy-link .heading_wrapper {
	text-align: center;
	padding-bottom: 30px;
}
.copy-link .heading_wrapper h2 {
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
}
.copy-link .heading_wrapper h4 {
	font-size: 21px;
	font-weight: 400;
}
.copy-link .link-box {
	background-image: url("../img/link-back.png");
	padding: 50px 40px;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.copy-link .link-box div#inviteCode {
	display: flex;
}
.copy-link .link-box div#inviteCode input#link {
	width: 100%;
	border: 0;
	background: #e9e9e9;
	padding: 17px 20px;
	font-size: 14px;
	color: #414141d4;
	outline: none;
	box-shadow: none;
}
.copy-link .link-box div#inviteCode div#copy {
	background: #272727;
	padding: 0 15px;
	cursor: pointer;
}
.copy-link .link-box div#inviteCode div#copy i {
	color: #5d5d5d;
	font-size: 30px;
	display: flex;
	align-items: center;
	height: 60px;
}
.copy-link .link-box .button-group {
	margin-top: 30px;
	text-align: center;
}
.copy-link .link-box .button-group button.btn {
	background: #202021;
	color: #fff;
	padding: 10px 80px;
	border-radius: 0;
}
/* Invite Css End Here */
