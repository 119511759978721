/* Product Css Start Here */
.product-sec {
	padding: 60px 0;
}
.product-sec .heading-wrapper {
	padding-bottom: 35px;
	text-align: center;
	font-family: "nexa-bold";
}
.product-sec .profile {
	text-align: center;
	padding-bottom: 50px;
}
.product-sec .profile figure img {
	width: 120px;
}
.product-sec .profile .name {
	margin-top: 24px;
}
.product-sec .profile h3 {
	font-size: 32px;
	font-weight: 400;
}
.product-sec .profile h4 {
	color: #9b9b9b;
}
.product-box {
	margin-bottom: 50px;
}
.product-box .pro-img {
	background: #fff;
	text-align: center;
	border-radius: 12px;
	margin-bottom: 30px;
}
.product-box .pro-img img {
	width: 100%;
	height: 170px;
	border-radius: 9px;
}
.product-box .content-wrapper .head {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product-box .content-wrapper .brand-name h4 {
	font-size: 14px;
	font-weight: 200;
}
.product-box .content-wrapper .head h3 {
	font-size: 20px;
}
.product-box .content-wrapper .head h4 {
	color: #f9b84c;
}
.product-box .content-wrapper p {
	font-size: 14px;
	color: #ffffffad;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.product-box .content-wrapper ul.stars {
	padding: 0;
	list-style: none;
	margin: 0;
}
.product-box .content-wrapper ul.stars li {
	display: inline-block;
	margin-right: 5px;
	color: #ff9f00;
}
.product-box .content-wrapper .button-group {
	text-align: end;
}
.product-box .content-wrapper .button-group a.btn {
	background-image: linear-gradient(
		to right top,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
	font-size: 16px;
	color: #ffffff;
	padding: 8px 20px;
	margin-top: 15px;
	border-radius: 10px;
	font-weight: 500;
	transition: 0.6s;
}
.product-box .content-wrapper .button-group a.btn:hover {
	background: #383838;
}
.product-sec .title h4 {
	font-size: 25px;
	color: #f9b84c;
	padding-bottom: 15px;
}
.product-sec .add-new {
	background: #313131;
}
.product-sec .title h4 {
	font-size: 25px;
	color: #f9b84c;
	padding-bottom: 15px;
}
.product-sec .add-new {
	background: #313131;
	height: 75%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	text-align: center;
}
.product-sec .add-new h4 {
	font-size: 18px;
	font-weight: 400;
}
.product-sec .add-new svg {
	margin-bottom: 30px;
}
.product-sec .add-new a {
	color: inherit;
	text-decoration: inherit;
}
/* Product Css End Here */
