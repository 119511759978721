/* Wishlist Css Start Here */
.wishlist_sec {
	padding: 80px 0;
}
.wishlist_sec .heading-wrapper {
	padding-bottom: 30px;
	text-align: center;
}
.wishlist_sec .heading-wrapper h2 {
	font-size: 30px;
	color: #f9b84c;
}
.wishlist_sec table.table thead {
	color: #fff;
}
.wishlist_sec table.table thead th {
	border: 0;
	background: #ffffff0f;
	padding: 15px 0;
	width: 20%;
}
.wishlist_sec table.table td {
	border: 0;
	vertical-align: middle;
	padding: 30px 0;
	width: 20%;
	color: #fff;
	border-bottom: 1px solid #37373794;
}
.wishlist_sec table.table tr:last-child td {
	border: 0;
}
.wishlist_sec table.table tr td .first {
	display: flex;
}
.wishlist_sec table.table tr td .first button.cancel {
	background: transparent;
	color: #fff;
	padding: 0;
	margin-right: 20px;
	border: 0px;
}
.wishlist_sec table.table tr td .first img {
	border-radius: 10px;
}
.wishlist_sec table.table td:nth-child(3) {
	color: #ffb378;
	font-size: 18px;
}
.wishlist_sec table.table td.cartBtn button.btn {
	background-image: linear-gradient(
		to right top,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
	font-size: 14px;
	color: #ffffff;
	padding: 13px 50px;
	margin-top: 15px;
	width: 90%;
	border-radius: 10px;
}
.wishlist_sec table.table td figure {
	margin: 0;
}
.wishlist_sec .table-responsive ul.pagination {
	padding: 0;
	margin: 0;
	justify-content: center;
}
.wishlist_sec .table-responsive ul.pagination li.arrow i {
	background-image: linear-gradient(
		to right top,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 25px;
	font-weight: 700;
	width: 30px;
	margin: 0 5px;
	text-align: center;
}
.wishlist_sec .table-responsive ul.pagination {
	padding: 0;
	margin: 0;
	justify-content: center;
}
.wishlist_sec .table-responsive ul.pagination li.arrow i {
	background-image: linear-gradient(
		to right top,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 25px;
	font-weight: 700;
	width: 30px;
	margin: 0 5px;
	text-align: center;
}
.wishlist_sec .table-responsive ul.pagination li.pagi a {
	color: #000;
	background: #ffffff;
	width: 30px;
	text-align: center;
	margin: 0 3px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}
.wishlist_sec .table-responsive ul.pagination li.pagi.active a {
	background-image: linear-gradient(
		to right top,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
	color: #fff;
}
/* Wishlist Css End Here */
