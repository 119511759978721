/* Live Stream Css Start Here */
.hide {
  display: none;
}
.show {
  display: block;
}
.live-stream {
  padding: 50px 0;
}
.live-stream {
  padding: 50px 0;
}
.live-stream .stream .title-wrapper {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.live-stream .stream .title-wrapper .action-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}
.live-stream .stream .title-wrapper .left h4 {
  font-size: 16px;
  color: #ffffffa8;
  font-family: "Poppins";
  font-weight: 400;
  margin: 0;
}
.live-stream .stream .title-wrapper .left h2 {
  font-size: 32px;
  font-weight: 400;
}
.live-stream .stream p.hashtag span {
  font-size: 16px;
  color: #ffffffb5;
  margin-right: 15px;
}

.live-stream .stream p.description {
  font-size: 14px;
  color: #ffffffcf;
}
.live-stream .stream .title-wrapper .action-btn .btn {
  font-family: "Poppins";
  color: #fff;
  border: 1px solid #fff;
  margin-left: 15px;
  padding: 10px 20px;
  border-radius: 12px;
  transition: 0.6s;
}
.live-stream .stream .title-wrapper .action-btn .btn:hover {
  background: #ff0000;
  border-color: #ff0000;
}
.live-chat {
  background: #1c1c1c;
  padding: 20px 0;
  border-radius: 15px;
}
.live-chat .heading-wrapper {
  border-bottom: 1px solid #2f293a;
  text-align: center;
  padding-bottom: 5px;
  position: relative;
}
.live-chat .heading-wrapper h3 {
  font-size: 25px;
  font-weight: 500;
  font-family: "Poppins";
  text-transform: capitalize;
}
.live-chat .heading-wrapper {
  border-bottom: 1px solid #2f293a;
  text-align: center;
  padding-bottom: 5px;
  position: relative;
}
.live-chat .heading-wrapper button.setting {
  border: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  position: absolute;
  right: 30px;
  top: -5px;
}
.live-chat {
  background: #1c1c1c;
  padding: 20px 0;
  margin-left: 15px;
}
.live-chat .close {
  background: #dd4141;
  opacity: 1;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 34px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -10px;
}
.live-chat ul.messages {
  padding: 20px 15px;
}
.live-chat ul.messages {
  padding: 15px 15px;
  list-style: none;
  height: 350px;
  overflow: auto;
  /* z-index: 9; */
  position: relative;
}
.live-chat ul.messages::-webkit-scrollbar {
  width: 1em;
}
.live-chat ul.messages li .dropdown button.btn {
  white-space: unset;
  text-align: left;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  box-shadow: none !important;
  outline: none !important;
  font-size: 13px;
}
.live-chat ul.messages li .dropdown button.btn::after {
  display: none;
}
.live-chat ul.messages li .dropdown button.btn span {
  color: #ff8484;
}
.live-chat ul.messages li .dropdown ul.dropdown-menu {
  width: 100%;
}
.live-chat ul.messages li .dropdown ul.dropdown-menu:before {
  content: "";
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid transparent;
  border-bottom: 8px solid #363636;
  position: absolute;
  top: -16px;
  left: 20px;
}
.live-chat ul.messages li .dropdown ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2d2d2d;
  padding: 7px 30px;
}
.live-chat ul.messages li .dropdown ul.dropdown-menu a.dropdown-item svg {
  margin-right: 13px;
}
.live-chat ul.messages li .dropdown ul.dropdown-menu a.dropdown-item span {
  color: #9f9f9f;
}
.live-chat
  ul.messages
  li
  .dropdown
  ul.dropdown-menu
  li:last-child
  a.dropdown-item {
  border: 0;
}
.live-chat ul.messages li {
  font-size: 14px;
  margin-bottom: 10px;
}
.live-chat ul.messages li span.green {
  color: #c6ff84;
}
.live-chat ul.messages li span.purple {
  color: #98a4ff;
}
.live-chat ul.messages li span.pnk {
  color: #ff9aec;
}
.live-chat ul.messages li.back-purple {
  background: #a4b6ff75;
  padding: 7px 8px;
  border-radius: 6px;
}
.live-chat ul.messages li.orange-back span.yellow {
  color: #ffbf00;
}
.live-chat ul.messages li.orange-back {
  background: #fb7c1bad;
  padding: 7px 8px;
  border-radius: 6px;
}
.live-chat ul.messages li span.lght-purple {
  color: #cb8aff;
}
.live-chat ul.messages li span.pinkish {
  color: #ff9898;
}
.live-chat .bottom-bar {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.live-chat .bottom-bar .emoji {
  background: #0000009e;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
  z-index: 2;
}
.live-chat .bottom-bar .form-group {
  flex: 1;
  display: flex;
  position: relative;
}
.live-chat .bottom-bar .form-group button.react-input-emoji--button {
  position: absolute;
  right: 35px;
  z-index: 1;
}
.live-chat .bottom-bar .form-group button.react-input-emoji--button svg {
  fill: #fff;
}
.live-chat .bottom-bar .form-group button.btn {
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none !important;
  outline: none !important;
  position: absolute;
  right: 12px;
  top: 17px;
  cursor: pointer;
  z-index: 1;
}
.live-chat .bottom-bar .form-group .react-input-emoji--container {
  background: #3e3e3e;
  border: 0;
  padding: 5px 10px;
  color: #ffffff;
  border-radius: 50px !important;
}
.live-chat
  .bottom-bar
  .form-group
  .react-input-emoji--container
  .react-input-emoji--placeholder {
  font-family: "Poppins";
}
div.hearts {
  width: 100px;
  height: 300px;
  position: absolute;
  bottom: 0px;
  right: 40px;
  margin-left: -50px;
}
div.heart {
  width: 30px;
  height: 30px;
  opacity: 1;
  position: absolute;
  bottom: 0;
  display: none;
}
div.heart i {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
}
.colOne {
  color: #fc771d;
}
.colTwo {
  color: #fc771d;
}
.colThree {
  color: #fc771d;
}
.colFour {
  color: #fc771d;
}
.colFive {
  color: #fc771d;
}
.colSix {
  color: #fc771d;
}
@keyframes flowOne {
  0% {
    opacity: 0;
    bottom: 0;
    left: 14%;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 0;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 80%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 18%;
  }
}
@keyframes flowTwo {
  0% {
    opacity: 0;
    bottom: 0;
    left: 0;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 11%;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 60%;
  }
  100% {
    opacity: 0;
    bottom: 80%;
    left: 0;
  }
}
@keyframes flowThree {
  0% {
    opacity: 0;
    bottom: 0;
    left: 0;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 30%;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 70%;
  }
  100% {
    opacity: 0;
    bottom: 90%;
    left: 0;
  }
}
.stream-setting {
  background: #1c1c1c;
  padding: 40px 40px;
  border-radius: 20px;
  position: relative;
}
.stream-setting button.setting {
  border: 0;
  background: transparent;
  outline: none;
  box-shadow: none;
  position: absolute;
  right: 20px;
  top: 20px;
}
.stream-setting .heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 30px;
}
.stream-setting .heading-wrapper h3.txt {
  font-size: 20px;
  margin: 0;
}
.stream-setting form .form-group .form-control {
  border: 0;
  background: transparent;
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
  padding: 0 0;
  color: #fff;
  box-shadow: none;
  outline: none;
}
.stream-setting form .form-group .form-control::placeholder {
  color: #d9d9d9;
}
.stream-setting form .add-stream {
  padding: 10px 0 30px;
}
.stream-setting form .add-stream button.btn {
  background: #373737;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 8px 25px;
}
.stream-setting form .add-stream button.btn svg {
  margin-right: 4px;
}
.stream-setting form ul.toggle-setting {
  padding: 0;
  list-style: none;
}
.stream-setting form ul.toggle-setting span {
  font-size: 14px;
}
/* Live Stream Css End Here */

/* Live Stream Setting Css Start Here */
.stream-setting form ul.toggle-setting li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.stream-setting .switch {
  position: relative;
  display: inline-block;
  height: 18px;
  width: 40px;
}
.stream-setting .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.stream-setting .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
.stream-setting .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  border-radius: 50px;
  bottom: 4px;
  background-color: #fd6222;
  transition: 0.4s;
  top: 2px;
  right: 23px;
}
.stream-setting input:checked + .slider {
  background-color: #fd6222;
}
.stream-setting .stream-setting input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.stream-setting input:checked + .slider:before {
  transform: translateX(20px);
  background: #fff;
}
/* Rounded sliders */
.stream-setting .slider.round {
  border-radius: 34px;
}
.stream-setting .slider.round:before {
  border-radius: 50%;
}
.stream-setting .button-group .btn {
  background-image: linear-gradient(
    to right top,
    #fe5b25,
    #fe6c1e,
    #fd7c18,
    #fb8b14,
    #f99914
  );
  font-size: 14px;
  color: #ffffff;
  padding: 10px 50px;
  margin-top: 15px;
  width: 100%;
  border-radius: 10px;
  font-weight: 500;
}
.stream-setting .button-group {
  margin-top: 70px;
}
/* Live Stream Setting Css Start Here */

/* Analytics Modal Css Start Here */
#analyticsModal .modal-content {
  background: transparent;
  border: 0;
  box-shadow: none;
  text-align: center;
}
#analyticsModal {
  background: #ffffff08 !important;
  -webkit-backdrop-filter: blur(11px) !important;
  backdrop-filter: blur(14px) !important;
}
#analyticsModal .close button {
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
}
#analyticsModal .close {
  opacity: 1;
  position: absolute;
  right: -70%;
  top: 5%;
}
#analyticsModal .analytics-box .heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#analyticsModal .analytics-box .heading-wrapper h3 {
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 600;
}
#analyticsModal .analytics-box .streamer-detail figure.streamer-img {
  margin: 0 0 15px;
}
#analyticsModal .analytics-box .streamer-detail h4.name {
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
}
#analyticsModal .modal-dialog {
  max-width: 550px;
}
#analyticsModal .analytics-box ul.total-stream-time {
  padding: 10px 0 15px;
  list-style: none;
  margin: 0;
  border-bottom: 1px solid #dddddd47;
  margin-bottom: 15px;
}
#analyticsModal .analytics-box ul.total-stream-time li {
  display: inline-block;
  text-align: center;
}
#analyticsModal .analytics-box ul.total-stream-time li.seprator {
  margin: 0 18px;
}
#analyticsModal .analytics-box .new-followers {
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd47;
}
#analyticsModal .analytics-box .new-followers h4.title {
  font-size: 18px;
  padding-bottom: 15px;
}
#analyticsModal .analytics-box .new-followers ul.new {
  padding: 0;
  margin: 0;
  list-style: none;
}
#analyticsModal .analytics-box .new-followers ul.new li {
  display: inline-block;
  margin: 0 -9px;
}
#analyticsModal .analytics-box .new-followers h4.follower-count {
  font-size: 14px;
  font-family: "Poppins";
  color: #ffffffa8;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 0;
}
#analyticsModal .analytics-box .nugs-earned {
  padding: 25px 0;
}
#analyticsModal .analytics-box .nugs-earned h4.title {
  color: #eeeeee;
  font-size: 18px;
  font-family: "Poppins";
}
#analyticsModal .analytics-box .nugs-earned ul.nuggs {
  padding: 10px 0 0;
  list-style: none;
  margin: 0;
}
#analyticsModal .analytics-box .nugs-earned ul.nuggs li {
  display: inline-block;
  text-align: center;
}
#analyticsModal .analytics-box .nugs-earned ul.nuggs li:nth-child(2) {
  margin: 0 40px;
}
#analyticsModal .analytics-box .nugs-earned ul.nuggs h5 {
  font-family: "Poppins";
  font-size: 11px;
  color: #eeeeee;
  text-transform: uppercase;
  margin-top: 8px;
}
#analyticsModal .analytics-box .nugs-earned ul.nuggs span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
#analyticsModal .analytics-box .highest-view {
  padding-top: 10px;
}
#analyticsModal .analytics-box .highest-view h4 {
  font-family: "Poppins";
  font-size: 16px;
  text-transform: uppercase;
}
#analyticsModal .analytics-box .highest-view h4.total {
  color: #f9b84c;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
/* Analytics Modal Css End Here */

/* Add Stareamer Modal Start Here */
#addStreamerModal {
  background: #fff0;
  backdrop-filter: blur(12px);
}
#addStreamerModal .modal-dialog {
  max-width: 650px;
}
#addStreamerModal .modal-content {
  background: #242526;
  padding: 20px 50px;
  border-radius: 15px;
}
#addStreamerModal .modal-content .close {
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 1;
}
#addStreamerModal .modal-content .close button.close-btn {
  border: 0;
  box-shadow: none;
  outline: gainsboro;
  background: transparent;
}
#addStreamerModal .add-streamer-box .sarch-bar {
  display: flex;
  margin-bottom: 30px;
}
#addStreamerModal .add-streamer-box .sarch-bar .form-group {
  flex: 1;
  margin: 0;
}
#addStreamerModal .add-streamer-box .sarch-bar .form-group input {
  border: 0;
  border-bottom: 1px solid #3b3b3b;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  padding: -1px 0;
}
#addStreamerModal .add-streamer-box .all-streamer label {
  display: block !important;
}
#addStreamerModal label.streamer-container {
  display: flex;
  align-items: center;
}
#addStreamerModal .add-streamer-box label.streamer-container .streamer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 12px;
  padding-right: 15px;
}
#addStreamerModal
  .add-streamer-box
  label.streamer-container
  .streamer-box
  .right
  span.dot {
  width: 14px;
  height: 14px;
  background: #fff;
  display: block;
  border-radius: 50%;
}
#addStreamerModal
  .add-streamer-box
  label.streamer-container
  input:checked
  ~ span.checkmark
  .streamer-box
  .right
  span.dot {
  background: #fe5b25;
  box-shadow: 0px 0px 0px 3px #fff;
}
#addStreamerModal .add-streamer-box label.streamer-container .left {
  display: flex;
  align-items: center;
}
#addStreamerModal .add-streamer-box label.streamer-container .left figure {
  margin: 0 15px 0 0px;
}
#addStreamerModal .add-streamer-box label.streamer-container .left h4 {
  margin: 0;
  font-size: 15px;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
}
#addStreamerModal .add-streamer-box label.streamer-container .left figure img {
  width: 35px;
  object-fit: cover;
}
#addStreamerModal .button-group button.btn {
  background-image: linear-gradient(
    to right top,
    #fe5b25,
    #fe6c1e,
    #fd7c18,
    #fb8b14,
    #f99914
  );
  font-size: 14px;
  color: #ffffff;
  padding: 10px 50px;
  margin-top: 15px;
  width: 100%;
  border-radius: 10px;
  margin-top: 25px;
}
#addStreamerModal .all-streamer {
  height: 350px;
  overflow: auto;
}
#addStreamerModal .all-streamer::-webkit-scrollbar {
  width: 1em;
}
/* Add Stareamer Modal End Here */

@keyframes flowOne {
  0% {
    opacity: 0;
    bottom: 0;
    left: 14%;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 0;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 80%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 18%;
  }
}
@keyframes flowTwo {
  0% {
    opacity: 0;
    bottom: 0;
    left: 0;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 11%;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 60%;
  }
  100% {
    opacity: 0;
    bottom: 80%;
    left: 0;
  }
}
@keyframes flowThree {
  0% {
    opacity: 0;
    bottom: 0;
    left: 0;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 30%;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 70%;
  }
  100% {
    opacity: 0;
    bottom: 90%;
    left: 0;
  }
}
