/* Chat Css Start Here */
.chat_sec {
	padding: 60px 0;
}
.chat_sec .row {
	border: 1px solid #dddddd5e;
	border-radius: 10px;
}
.chat_sec .chat-list {
	border-right: 1px solid #ddd;
	/* padding: 20px 0px 0; */
}
.chat_sec .chat-list .title {
	padding: 0 20px 5px;
}
.chat_sec .chat-list ul.all-chats {
	padding: 0;
	margin: 0;
	list-style: none;
}
.chat_sec .chat-list ul.all-chats .chat {
	display: flex;
	align-items: flex-start;
}
.chat_sec .chat-list ul.all-chats .chat figure {
	margin: 0;
}
.chat_sec .chat-list ul.all-chats .chat .name h4 {
	font-size: 15px;
	line-height: 15px;
}
.chat_sec .chat-list ul.all-chats .chat .name h5 {
	font-size: 13px;
}
.chat_sec .chat-list ul.all-chats .chat .name h5.online {
	font-size: 10px;
	color: white;
	padding-left: 15px;
	position: relative;
}
.chat_sec .chat-list ul.all-chats .chat {
	border-bottom: 1px solid #dddddd5e;
	padding: 15px 20px;
}
.chat_sec .chat-list ul.all-chats .chat .name {
	padding-left: 10px;
	position: relative;
}
.chat_sec .chat-list ul.all-chats .chat .name h5.online span.dot {
	background: #3bd307;
	width: 7px;
	height: 7px;
	position: absolute;
	left: 1px;
	border-radius: 50%;
	top: 2px;
}
.chat_sec .chat-list ul.all-chats li:last-child .chat {
	border-bottom: 0;
}
.chat_sec .chat-list ul.all-chats .actve-chat {
	background: linear-gradient(
		to right,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
}
.chat_sec .chat-list ul.all-chats .chat .name h5.offline {
	font-size: 10px;
	color: white;
	padding-left: 15px;
	position: relative;
}
.chat_sec .chat-list ul.all-chats .chat .name h5.offline .dot {
	background: red;
	width: 7px;
	height: 7px;
	position: absolute;
	left: 1px;
	border-radius: 50%;
	top: 2px;
}
.the-chats .chat-user {
	display: flex;
	align-items: flex-start;
	width: 100%;
	background: linear-gradient(
		to right,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
	padding: 10px 15px;
}
.the-chats .chat-user .name h4 {
	font-size: 15px;
	line-height: 15px;
}
.the-chats .chat-user .name h5 {
	font-size: 10px;
	color: white;
	padding-left: 15px;
	position: relative;
}
.the-chats .chat-user .name h5 span.dot {
	background: #3bd307;
	width: 7px;
	height: 7px;
	position: absolute;
	left: 1px;
	border-radius: 50%;
	top: 2px;
}
.the-chats .chat-user .name {
	margin-left: 10px;
}
.the-chats .chat-user figure {
	margin: 0;
}
.the-chats {
	height: 100%;
	position: relative;
	padding-bottom: 100px;
	background: transparent;
	padding: 0 0 0 0px;
}
.the-chats .chat-user {
	display: flex;
	align-items: flex-start;
	width: 100%;
	background: #f99914;
	padding: 5px 15px;
}
.the-chats .my-chat {
	height: 500px;
	overflow-y: scroll;
}
.the-chats .my-chat::-webkit-scrollbar {
	width: 0;
}
.my-chat-inner {
	height: 100%;
	padding: 0 15px;
}
.send-message {
	text-align: right;
	margin: 12px 0 0 auto;
	display: table;
}
.message-body {
	background-color: #202020;
	font-family: Poppins;
	padding: 10px 19px;
	text-align: left;
	border-radius: 50px;
}
.message-body span {
	color: #ffffff;
	font-size: 12px;
}
.send-on {
	text-align: left;
	clear: both;
	margin-top: 6px;
}
.send-on span {
	color: #fff;
	font-size: 10px;
	opacity: 0.5;
	font-family: "Poppins";
}
.recive-message {
	margin-top: 30px;
	display: flex;
}
.recive-message .recieve-from-img {
	margin-bottom: 10px;
}
.my-chat-inner .recive-message .recieve-from-img {
	margin-right: 12px;
}
.message-recieved {
	margin: 0 auto 0 0;
	display: table;
}
.that-message {
	background-color: #202020;
	padding: 10px 20px;
	border-radius: 50px;
}
.that-message span {
	color: #fff;
	font-family: "poppins";
	font-size: 12px;
}
.recieved-on {
	text-align: right;
}
.recieved-on span {
	color: #fff;
	font-size: 10px;
	opacity: 0.5;
}
.send-new-message {
	position: absolute;
	width: 100%;
	bottom: 20px;
	display: flex;
	left: 0;
	padding: 0 30px 0 25px;
}
.for-new-message {
	width: 98%;
	margin-right: 2%;
}
.for-new-message input {
	background: #000;
	border: 0 !important;
	box-shadow: none;
	color: #fff;
	height: 57px;
	outline: none;
	padding: 0 10px;
	width: 100%;
	border-radius: 50px;
}
.for-new-message input::placeholder {
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	padding-left: 10px;
	opacity: 0.5;
}
.for-send-msg-btn button {
	background: linear-gradient(
		to right,
		#fe5b25,
		#fe6c1e,
		#fd7c18,
		#fb8b14,
		#f99914
	);
	border: 0px;
	width: 125px;
	height: 50px;
	color: #ffffff;
	font-family: "Poppins";
	font-size: 16px;
	border-radius: 50px;
}
/* Chat Css End Here */
